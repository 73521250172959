import { ToastContainerProps } from 'react-toastify';

export const searchInputPlaceholder = 'Type min. 3 letters and press enter to search';

export const maxLength10 = 10;
export const maxLength30 = 30;
export const maxLength150 = 150;
export const maxLength100 = 100;
export const maxLength800 = 800;
export const maxLength10000 = 10000;
export const maxLength100000 = 100000;

export const backSpaceKeyCode = 8;
export const tabSpacekeyCode = 9;
export const deleteKeyCode = 46;
export const enterKeyCode = 13;
export const arrowLeftKeyCode = 37;
export const arrowUpKeyCode = 38;
export const arrowRightKeyCode = 39;
export const arrowDownKeyCode = 40;


export const imageFileExtension = ['jpeg', 'png', 'jpg'];

export const fileSize = '5,242,880';

export const toastOptObj: ToastContainerProps = {
    position: 'top-center',
    autoClose: 3500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    theme: 'colored',
};

export const rowsPerPageOptions = [10, 25, 50, 75];

export const statusLists: { name: string; code: boolean }[] = [
    { name: 'Active', code: true },
    { name: 'Inactive', code: false },
];


export const allFeaturedModules = {
    offices: 'Offices',
    statesCitiesMaster: 'States-Cities Master',
    pricingVersion: 'Pricing Version',
    dashboard: "Dashboard",
    wallOrders: "Waltz Orders",
    glassOrders: "Glass Orders",
    transactions: "Transactions",
    orderDue: "Order Due",
    settings: "Settings",
    downloads: "Downloads",
    analytics: "Analytics",
    reports: "Reports",
    orderReport: "Order Report",
    generalSettings: "General Settings",
    states: "States",
    cities: "Cities",
    systems: "Systems",
    subSystems: "Sub System",
    systemTypes: "System Types",
    models: "Models",
    frameColors: "Frame Colors",
    glassCategories: "Glass Categories",
    glassSubCategories: "Glass Sub Categories",
    glassFinishes: "Glass Finishes",
    glassVariants: "Glass Variants",
    handles: "Handles",
    locks: "Locks",
    designs: "Designs",
    grids: "Grids",
    users: "Users",
    hinges: "Hinges",
    kitchenHandlePositions: "Kitchen Handle Positions",
    kitchenHandle: "Kitchen Handle",
    glassOnlyFinish: "Glass Only Finishes",
    kitchenType: "Kitchen Type",
    waltzCategories: "Waltz Categories",
    waltzSubCategories: "Waltz Sub Categories",
    waltzFinishes: "Waltz Finishes",
    waltzVariants: "Waltz Variants",
    roleManagement: "Role Management",
    changePassword: "Change Password",
    PricingVersionsOrderWiseCounts: "Pricing Versions Order Wise Counts"
};

export const noDataAvailableInTable = "No data available in table.";

export const emptyMultiSelectSearchMessage = 'No results found';